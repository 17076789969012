@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
font-family: "Titillium Web Bold";
src: url("/assets/fonts/TitilliumWeb-Bold.ttf") format("truetype");
}
@font-face {
font-family: "Exo ExtraBold";
src: url("/assets/fonts/Exo-ExtraBold.ttf") format("truetype");
}

/* h1,h2 {
  font-family: "Exo ExtraBold";
  font-weight:800;
} */

@layer base {
  /* https://www.tints.dev/cartloom/8BB062 */
  :root {
    --color-primary-50: 248, 250, 245;
    --color-primary-100: 238, 243, 232;
    --color-primary-200: 218, 229, 205;
    --color-primary-300: 194, 211, 172;
    --color-primary-400: 171, 196, 143;
    --color-primary-500: 140, 175, 102;
    --color-primary-600: 123, 159, 84;
    --color-primary-700: 108, 138, 74;
    --color-primary-800: 90, 114, 61;
    --color-primary-900: 66, 85, 45;
  }
}

@layer components {
  html,
  body {
    scroll-behavior: smooth;
  }

  .skewed {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2c3e50;
    z-index: 0;
    transform: skewY(-7deg);
    transform-origin: top left;
  }

  /* INPUT CSS FOR ALL THINGS FORMSNAP4/FORMLOOM5 */
  /* MODIFIED:  [ 2023-08-23 07:28:51 AM (OFFICE) ] */

  .input-group {
    @apply relative transition-all;
  }

  .input-group input[type="text"],
  .input-group input[type="email"],
  .input-group input[type="number"],
  .input-group input[type="password"] {
    @apply m-0 h-auto min-h-[50px] w-full transform rounded-md border border-gray-300 bg-white object-center font-medium text-gray-800  duration-150 focus:border-primary-400 ease-in-out focus:shadow-none focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-offset-white focus:ring-primary-500/50;
  }

  /* Dropdown / Autoomplete (popup menu) */
  .input-group.dropdown button {
    @apply inline-flex h-[50px] w-full transform items-center justify-between rounded-md border border-gray-300 bg-white p-3 pb-1 pl-3 pt-5 font-medium duration-150 ease-in-out focus:border-primary-500 focus:border-primary-500 focus:shadow-none focus:outline-none  focus:ring-4 focus:ring-primary-600/25 dark:border-gray-700 dark:bg-gray-900/50 dark:text-white dark:focus:ring-primary-500/25 dark:focus:ring-offset-2 dark:focus:ring-offset-primary-500;
  }
  .input-group.dropdown .menu,
  .input-group.autocomplete .menu {
    @apply absolute left-0 z-10 mt-2 w-full origin-top-left rounded-md border border-gray-400 bg-white/90 py-2 shadow-md backdrop-blur-sm focus:outline-none dark:border-gray-500 dark:bg-gray-900/90 dark:shadow-none;
  }
  .input-group.dropdown .menu a,
  .input-group.autocomplete .menu a {
    @apply block px-4 py-0.5 font-medium text-gray-500 hover:bg-primary-100 hover:text-primary-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white;
  }

  .input-btn-group input[type="text"],
  .input-btn-group input[type="email"],
  .input-btn-group input[type="password"] {
    @apply m-0 h-auto w-full transform rounded-none rounded-l-md border border-gray-300 bg-transparent bg-white object-center p-3 text-sm  text-gray-800 shadow-none outline-none duration-150 ease-in-out;
  }

  /* Select Menu */
  .input-group select,
  button.pseudo-select {
    @apply h-[50px] w-full transform rounded-md border-gray-300 bg-white p-3 pb-1 pl-3 pt-5 font-medium duration-150 ease-in-out focus:border-primary-500 focus:shadow-none focus:outline-none  focus:ring-4 focus:ring-primary-600/25 dark:border-gray-700 dark:bg-gray-900/50 dark:text-white dark:focus:ring-primary-500/25 dark:focus:ring-offset-2 dark:focus:ring-offset-primary-500;
  }

  .input-group textarea ~ label {
    @apply absolute left-2 top-3 rounded-full bg-white p-1 dark:bg-gray-800;
  }

  .input-btn-group ~ button {
    @apply relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700  transition duration-150 ease-in-out hover:bg-white hover:text-gray-500 focus:border-primary-300 focus:outline-none active:bg-gray-100 active:text-gray-700;
  }

  .input-group select.form-select,
  button.pseudo-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23737373' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M8 9l4-4 4 4m0 6l-4 4-4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.25em 1.25em;
    padding-right: 2.5rem;
    print-color-adjust: exact;
  }

  /* firefox fixes to remove red borders and focus */
  .input-group input[type="text"]:required,
  .input-group input[type="email"]:required,
  .input-group input[type="password"]:required,
  .input-group input[type="number"]:required {
    @apply shadow-none;
  }

  .input-group input[type="text"]:invalid,
  .input-group input[type="email"]:invalid,
  .input-group input[type="password"]:invalid,
  .input-group input[type="number"]:invalid {
    @apply shadow-none outline-none;
  }

  .input-btn-group.pre-icon input:not(:placeholder-shown) {
    @apply p-4 pb-1 pl-10 pt-6;
  }

  .input-group .good-icon,
  .input-group .bad-icon,
  .cb-group .good-icon,
  .cb-group .bad-icon {
    @apply block opacity-0 scale-50 transition ease-in-out duration-300;
  }

  .input-group.error .bad-icon,
  .cb-group.error .bad-icon {
    @apply opacity-100 scale-100;
  }

  .input-group.good .good-icon,
  .cb-group.good .good-icon {
    @apply opacity-100 block scale-100;
  }
  /* disable inputs during processing */
  .processing .input-group,
  .processing .input-btn-group {
    @apply pointer-events-none opacity-50;
  }
  .input-group.error input::placeholder,
  .input-group.error textarea::placeholder {
    @apply text-red-400 opacity-100;
  }

  /* Checkboxes and Radios */
  .cb-group input[type="checkbox"] {
    @apply focus:ring-primary-500 h-5 w-5 rounded border-gray-300 text-primary-600 transition;
  }

  .tooltip svg {
    @apply transition duration-100 ease-in-out;
  }
  .input-group .tooltip {
    @apply absolute right-0 top-0 mr-3 flex items-center;
  }
  .input-group .error-msg {
    @apply mt-1 hidden truncate text-xs text-red-600;
  }
  .input-group.error .error-msg {
    @apply mt-1 block truncate text-xs text-red-600;
  }

  /* price-list*/
  .price-list {
    @apply mt-7  divide-y;
  }
  .price-list li {
    @apply flex items-start gap-2 py-2 font-semibold text-gray-500;
  }
  .price-list li img {
    @apply hidden;
  }
  .price-list li .details {
    @apply text-sm font-normal text-gray-400;
  }
}

/* tippy css */

.tippy-box[data-animation="fade"][data-state="hidden"] {
  @apply opacity-0;
}
[data-tippy-root] {
  max-width: calc(100vw - 10px);
}
.tippy-box {
  @apply relative whitespace-normal rounded-md  bg-gray-600 text-base font-medium  text-white outline-none transition;
}
.tippy-box[data-placement^="top"] > .tippy-arrow {
  @apply bottom-0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  @apply top-0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^="left"] > .tippy-arrow {
  @apply right-0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}
.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}
.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
  @apply h-[16px] w-[16px] text-gray-600 before:absolute before:border-solid before:border-transparent;
}

.tippy-content {
  @apply relative z-10 px-6 py-2;
}
